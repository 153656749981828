// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import {
    faFacebookF,
    faTwitter,
    faInstagram
  } from '@fortawesome/free-brands-svg-icons';
import {
    faTruck,
    faShoppingBag,
    faCircle,
    faEnvelope,
    faLocationDot,
    faMobileScreenButton,
    faCirclePlus,
    faCircleMinus,
    faStar,
    faPrescriptionBottle,
    faBasketShopping,
    faHeart as faSolidHeart
  } from '@fortawesome/free-solid-svg-icons';
import {
    faClock,
    faHeart as faRegularHeart
  } from '@fortawesome/free-regular-svg-icons';

// add the imported icons to the library
library.add(
  faFacebookF,
  faTwitter,
  faInstagram,
  faTruck,
  faShoppingBag,
  faCircle,
  faEnvelope,
  faLocationDot,
  faMobileScreenButton,
  faClock,
  faPrescriptionBottle,
  faBasketShopping,
  faCirclePlus,
  faCircleMinus,
  faStar,
  faSolidHeart,
  faRegularHeart
);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();
