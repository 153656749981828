import Modal from "bootstrap/js/dist/modal";

/**
 * Show the announcements modal
 */
window.addEventListener("load", function (event) {
  // Get the  state from local storage
  const state = sessionStorage.getItem("state");

  // Get the seen announcements from local storage (contains a date when the user last dismissed the modal)
  const seen = sessionStorage.getItem(state + "-seen-announcements");

  // check if persist is in session storage
  const persist = sessionStorage.getItem("persist");

  // Check if the modal exists for the state we're currently looping through
  const announcementModal = document.getElementById("announcements-" + state);

  // Initialize the modal for the state we're currently looping through
  if (announcementModal) {
    const thisStatesModal = new Modal(announcementModal, {
      backdrop: "static",
      keyboard: false,
    });

    // Get all the announcements for the state we're currently looping through
    const thisStatesAnnouncements = document
      .getElementById("announcements-" + state)
      .getElementsByClassName("card-announcement");

    // Loop through all the announcements for the state we're currently looping through
    //  and check if their start date is greater than the last seen date AND if their start date is less than today AND that end late is greater than today
    for (let i = 0; i < thisStatesAnnouncements.length; i++) {
      const announcementStartDate = thisStatesAnnouncements[i].getAttribute(
        "data-announcement-start"
      );
      const announcementEndDate = thisStatesAnnouncements[i].getAttribute(
        "data-announcement-end"
      );
      if (
        announcementModal.getAttribute("data-persist-announcements") ===
          "true" &&
        !persist
      ) {
        thisStatesModal.show();
      } else if (
        announcementModal.getAttribute("data-persist-announcements") ===
          "false" &&
        new Date(seen) <= new Date(announcementStartDate) &&
        (new Date(announcementStartDate) <= new Date() && new Date(announcementEndDate) >= new Date())
      ) {
        // The announcement is newer than the last seen date, and doesn't start after today or end before today, so show the modal.
        thisStatesModal.show();
        break;
      }
    }
  }

  /**
   * Add an event listener on each announcement modal close button
   */
  let announcementsModals = document.getElementsByClassName(
    "announcements-modal"
  );
  for (let i = 0; i < announcementsModals.length; i++) {
    announcementsModals[i].addEventListener("hidden.bs.modal", () => {
      if (
        announcementModal.getAttribute("data-persist-announcements") === "true"
      ) {
        sessionStorage.setItem("persist", true);
      } else {
        const state = sessionStorage.getItem("state");
        sessionStorage.setItem(state + "-seen-announcements", new Date());
      }
    });
  }

  /**
   * Add an event listener on each announcement CTA button
   */
  const announcementCtas = document.getElementsByClassName(
    "call-to-action-announcement-button"
  );
  for (let i = 0; i < announcementCtas.length; i++) {
    announcementCtas[i].addEventListener("click", () => {
      if (
        announcementModal.getAttribute("data-persist-announcements") === "true"
      ) {
        sessionStorage.setItem("persist", true);
      } else {
        const state = sessionStorage.getItem("state");
        sessionStorage.setItem(state + "-seen-announcements", new Date());
      }
    });
  }
});
