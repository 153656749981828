import Modal from "bootstrap/js/dist/modal";
import Cookies from "js-cookie";
import _ from "lodash";

// Get the age gate modal
let ageGate = new Modal(document.getElementById("age-gate"), {
  backdrop: "static",
  keyboard: false,
});

/**
 * Show the age-gate modal
 * Check for existance of the session storage item "agegate". If it exists, don't show the modal.
 * If it doesn't exist, show the modal.
 */
window.addEventListener("load", function () {
  /**
   * If state is set don't show the modal
   */
  if (sessionStorage.getItem("agegate")) {
    console.log("You're old enough!");
    return;
  } else {
    /**
     * If state is not set, show the modal
     */
    console.log("Confirm that you're old enough!");
    ageGate.show();
  }
});

/**
 * Click handler to set the state cookie and clear the age gate
 */
document.addEventListener("click", function (event) {
  // If the clicked element doesn't have the right selector, bail
  if (!event.target.matches(".clear-agegate")) {
    return;
  } else {
    // Get the "state" data-value from the clicked element
    let state = event.target.dataset.state;

    // Clear the age gate
    clearAgeGate(state);
  }
});

function clearAgeGate(state) {
  // Get all cookies
  var cookies = document.cookie.split(";");

  // clear current state cookie if it exists
  var currentState = localStorage.getItem("state");
  if (currentState) {
    Cookies.remove("state-" + currentState);
  }

  // Tediously loop through each cookie to unset them
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    Cookies.remove(name, { path: "" });
  }

  console.log("the state is: " + state);

  // Set cookie and session storage
  Cookies.set("state-" + state, state);
  sessionStorage.setItem("agegate", true);
  sessionStorage.setItem("state", state);
  localStorage.setItem("state", state);

  /**
   * If this is the site's homepage, reload the page so that Netlify's redirects
   * can take effect and redirect the user to the correct state home page.
   *
   * If this is not the site's simply close the modal.
   */

  if (window.location.pathname === "/") {
    window.location.reload();
  } else {
    ageGate.hide();
  }
}
