/**
 * When .anchor-link-line a is clicked, add the class .show to the link target
 */
document.addEventListener("click", function (event) {
  // If the clicked element doesn't have the right selector, bail
  if (!event.target.matches(".anchor-link-line-item")) return;

  // remove "show" class from all elements with the .letter class
  document.querySelectorAll(".letter").forEach(function (el) {
    el.classList.remove("show");
  });
  var target = document.querySelector(event.target.getAttribute("href"));
  target.classList.add("show");
});
