import _ from "lodash";
import Cookies from "js-cookie";

/**
 * Function to mark the clicked store as favorite
 */
function markFavorite(store) {
  let el = document.getElementById(store);

  if (el) {
    el.classList.add("favorite-store");
  }
}

/**
 * Function to set the favorite
 */
function setFavorite(state, store, title, adultUse, medical) {
  // Conditionally set local storage if variables are set
  localStorage.setItem("state", state);
  localStorage.setItem("store", store);
  localStorage.setItem("storeTitle", title);
  localStorage.setItem("adultUse", adultUse);
  localStorage.setItem("medical", medical);
}

/**
 * Function to populate the navbar favorite element with store data
 */
function populateFavorite() {
  // Get all the elements
  let el = document.getElementById("navbarFavorite");
  let club = document.getElementById("clubBlock");
  let storeHeader = document.getElementById("page-header-store");
  let isFavoriteStore = document.body.classList.contains("favorite-store");
  let link = document.getElementById("navbarFavoriteStore");
  let favoriteCTA = document.getElementById("navbarFavoriteCTA");
  let adultUseEl = document.getElementById("navbarFavoriteAdultUse");
  let medicalEl = document.getElementById("navbarFavoriteMedical");

  // Get all the data
  let state = localStorage.getItem("state");
  let store = localStorage.getItem("store");
  let storeTitle = localStorage.getItem("storeTitle");
  let adultUse = localStorage.getItem("adultUse");
  let medical = localStorage.getItem("medical");

  // Hydrate nav-favorite with store data
  if (el) {
    let storeEl = el.querySelector(".name");

    if (storeTitle != "undefined") {
      storeEl.innerHTML = storeTitle;
    }

    link.href = "/" + state + "/" + store + "/";

    if ((adultUse == "true" || adultUse == "disabled") && state !== 'georgia') {
      adultUseEl.classList.remove("d-none");
      adultUseEl.href = "/" + state + "/" + store + "/recreational/";
    } else {
      adultUseEl.classList.add("d-none");
    }

    // if adultUse is "disabled" add the disabled class
    if (adultUse == "disabled") {
      adultUseEl.classList.add("disabled");
    }

    if (medical == "true" && state !== 'georgia') {
      medicalEl.classList.remove("d-none");
      medicalEl.href = "/" + state + "/" + store + "/medical/";
    } else {
      medicalEl.classList.add("d-none");
    }

    // if medical is "disabled" add the disabled class
    if (medical == "disabled") {
      medicalEl.classList.add("disabled");
    }

    if (store) {
      el.classList.remove("d-none");
    }
  }

  if (state === 'georgia') {
    favoriteCTA.href = "https://lab.alpineiq.com/join/c/3150/7850";
    favoriteCTA.target = "_blank";
    favoriteCTA.classList.remove("d-none");
    el.classList.remove("d-none");
  }

  // Hydrate the club button with store data
  if (club) {
    let link = club.querySelector(".call-to-action-button");
    link.href = "/club/";
    club.classList.remove("d-none");
  }

  // Set the favorite button tooltip text based on whether this is the current store
  if (storeHeader && isFavoriteStore) {
    let button = storeHeader.querySelector(".favorite-button");

    button.title = "This is your favorite location!";
    button.setAttribute(
      "data-bs-original-title",
      "This is your favorite location!"
    );
  }
}

/**
 * Populate the favorite element on page load
 */
window.addEventListener("load", function (event) {
  // Get store and state from local storage
  let store = localStorage.getItem("store");
  let state = localStorage.getItem("state");
  let cookie = Cookies.get("state-" + state);
  let noStateCookie = Cookies.get("state-none");

  /**
   * If state is set, append the correct state's stores to the header, don't show the modal
   */
  if (noStateCookie) {
    return;
  } else if (cookie && store) {
    markFavorite(store);
    populateFavorite();
  }
});

/**
 * Click handler to set the state and store in local storage
 */
document.addEventListener("click", function (event) {
  // If the clicked element doesn't have the right selector, bail
  if (!event.target.matches(".set-favorite")) return;

  let state = event.target.dataset.state;
  let store = event.target.dataset.store;
  let title = event.target.dataset.storetitle;
  let adultUse = event.target.dataset.adultuse;
  let medical = event.target.dataset.medical;

  setFavorite(state, store, title, adultUse, medical);
});

/**
 * Click handler to customize experience based age-gate data
 */
window.addEventListener("load", function (event) {
  // If the clicked element doesn't have the right selector, bail

  // Get the "store" data-value from the clicked element
  let store = localStorage.getItem("store");

  markFavorite(store);
  populateFavorite();
});
