document.addEventListener(
  'click',
  function (event) {
    // Only fire if the target has id copy
    if (!event.target.matches('.copy-text')) return;

    if (!navigator.clipboard) {
      // Clipboard API not available
      return;
    }
    // Get the textarea referenced in the data-target attribute
    const textarea = document.querySelector(event.target.dataset.target);
    const text = textarea.value;
    try {
      navigator.clipboard.writeText(text);
      event.target.innerText = 'Copied to clipboard';
      setTimeout(function () {
        event.target.innerText = 'Click to copy';
      }, 1200);
    } catch (err) {
      console.error('Failed to copy!', err);
    }
  },
  false
);

/**
 * Strip whitespace and returns from all textareas with the class "copy"
 */
document.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('.copy').forEach(function (textarea) {
    textarea.value = textarea.value.replace(/\s+/g, ' ').trim();

    // Set the textarea to disabled
    textarea.disabled = true;
  });
});
